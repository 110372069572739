<template>
  <div
    v-if="$slots.left || $slots.center || $slots.right"
    class="flex items-center justify-between bg-white sm:bg-blue-50"
  >
    <div class="container h-full sm:bg-gradient-to-r sm:from-blue-50 sm:via-white sm:to-blue-50">
      <div class="flex h-full items-center justify-between text-sm">
        <div class="justify-start space-x-9 [&>a]:text-white [&>a]:opacity-90">
          <!-- @slot Slot for left links -->
          <slot name="left" />
        </div>
        <div class="justify-center space-x-9 [&>a]:text-white [&>a]:opacity-90">
          <!-- @slot Slot for center items -->
          <slot name="center" />
        </div>
        <div class="justify-end space-x-9 [&>a]:text-white [&>a]:opacity-90">
          <!-- @slot Slot for right items -->
          <slot name="right" />
        </div>
      </div>
    </div>
  </div>
</template>
